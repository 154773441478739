<template>
  <div
    id="brandMap"
    class="brand-map"
  >
    <div id="location">
      您现在所在位置：云端品牌库 > {{brandName}} > <span>{{cityName}}开关店分布</span>
    </div>
    <div
      id="container"
      :style="{'min-height': clientHeight + 'px'}"
    ></div>
    <div
      id="panel"
      class="scrollbar1"
      :style="{'min-height': clientHeight + 'px'}"
    >
      <div id="brandName">
        <p id="brandTittle">{{brandName}}</p>
        <p id="brandShop">{{cityName}}{{projectType}}在营门店<span>{{storeData.length}}家</span></p>
        <div
          v-if="projectType"
          class="brandtBtn fl"
        >
          {{projectType}}
        </div>
        <div
          v-if="state"
          class="brandtBtn fl"
          style="margin-left:15px"
        >
          {{state==='6'?'新开门店':(state==='10'?'在营门店':'新关门店')}}
        </div>
        <div
          v-if="showTime"
          class="brandtBtn fl"
          style="margin-left:15px"
        >
          {{showTime}}
        </div>
      </div>
      <div class="store">
        <span id="storeTitle">
          门店分布明细
        </span>
      </div>
      <div class="BrandList w-table-content">
        <ul id="myList">
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import AMap from 'AMap'
import AMapUI from 'AMapUI'
export default {
  name: 'brandMap',
  components: {},
  data () {
    return {
      clientHeight: 0,
      cityName: '',
      projectType: '',
      showTime: '',
      time: '',
      state: '',
      brandName: '',
      storeData: []
    }
  },
  mounted () {
    this.createAmap()
    this.clientHeight = `${document.documentElement.clientHeight}` - 122
    this.brandId = this.$route.query.brandId
    this.cityId = this.$route.query.cityId
    this.cityName = this.$route.query.cityName
    this.seriesId = this.$route.query.seriesId
    this.gbid = this.$route.query.gbid
    this.projectType = this.$route.query.projectType
    this.regionalType = this.$route.query.regionalType
    this.state = this.$route.query.state
    this.time = (this.$route.query.time && this.$route.query.time !== 'null') ? this.$route.query.time : ''
    this.showTime = (this.$route.query.time && this.$route.query.time !== 'null') ? this.$route.query.time.replace(/-/g, '年第') + '季度' : ''
    this.brandName = this.$route.query.brandName
  },
  methods: {
    createAmap () {
      const that = this
      const map = new AMap.Map('container', {
        zoom: 11 // 级别
      })
      AMapUI.loadUI(
        [
          'control/BasicControl',
          'misc/MarkerList',
          'overlay/SimpleMarker',
          'overlay/SimpleInfoWindow'
        ],
        function (BasicControl, MarkerList, SimpleMarker, SimpleInfoWindow) {
          // 添加一个缩放控件
          map.addControl(
            new BasicControl.Zoom({
              position: 'lb' // 位置在屏幕左下
            })
          )
          // 即jQuery/Zepto
          var $ = MarkerList.utils.$
          // 默认的图标样式
          // var defaultIconStyle = '<div style="width: 24px; height: 24px;background: #ff680f; border: 2px solid #FFFFFF; box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35); border-radius: 50%;"></div>'
          var defaultIconStyle = '<div><img width="32" height="45" src="https://img3.winshang.com/wbms/wbms-def-c1.png" /></div>'
          // 鼠标hover时的样式
          // var hoverIconStyle = '<div style="width: 24px; height: 24px;background: #FF9D3F; border: 2px solid #FFFFFF; box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35); border-radius: 50%;"></div>'
          var hoverIconStyle = '<div><img width="32" height="45" src="https://img3.winshang.com/wbms/wbms-def-c2.png" /></div>'
          // 选中时的图标样式
          // var selectedIconStyle = '<div style="width: 24px; height: 24px;background: #FF9D3F; border: 2px solid #FFFFFF; box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35); border-radius: 50%;"></div>'
          var selectedIconStyle = '<div><img width="32" height="45" src="https://img3.winshang.com/wbms/wbms-def-c2.png" /></div>'
          var markerList = new MarkerList({
            map: map,
            // ListElement对应的父节点或者ID
            listContainer: 'myList', // document.getElementById("myList"),
            // 选中后显示
            // 从数据中读取位置, 返回lngLat
            getPosition: function (item) {
              return [item.lng, item.lat]
            },
            // 数据ID，如果不提供，默认使用数组索引，即index
            getDataId: function (item, index) {
              return item.projectId
            },
            // 信息窗口
            getInfoWindow: function (data, context, recycledInfoWindow) {
              var infoBody = ''
              let infoWindow = null
              infoBody = '<a class="toBrandDetail" target="_blank" href="http://' + window.location.host + '/#/projectDetail?id=' + data.projectId + '&source=1">' + '<div style="width: 114px; height: 28px; background: #FF9D3F; border: 1px solid #FF9D3F;border-radius: 3px;margin:0 auto;">' + '点击进入详情' + '</div>' + '</a>'
              infoWindow = new SimpleInfoWindow({
                infoTitle: data.projectName,
                infoBody: infoBody,
                offset: new AMap.Pixel(100, -25)
              })
              if (recycledInfoWindow) {
                recycledInfoWindow.setInfoTitle(data.projectName)
                recycledInfoWindow.setInfoBody(infoBody)
                return recycledInfoWindow
              }
              return infoWindow
            },
            // 构造marker用的options对象, content和title支持模板，也可以是函数，返回marker实例，或者返回options对象
            getMarker: function (data, context, recycledMarker) {
              var label = ' '
              if (recycledMarker) {
                recycledMarker.setIconLabel(label)
                return
              }
              return new SimpleMarker({
                containerClassNames: 'my-marker',
                iconStyle: defaultIconStyle,
                iconLabel: label
              })
            },
            // 构造列表元素，与getMarker类似，可以是函数，返回一个dom元素，或者模板 html string
            getListElement: function (data, context, recycledListElement) {
              var label = ' '
              // 使用模板创建
              var innerHTML = MarkerList.utils.template('<div class="poi-imgbox" style="float:left;margin-top:16px;">' + '<img width="90px" height="90px" src="<%- data.projectPic %>" />' +
                '</div>' +
                '<div class="poi-info-left" style="float:left;width:270px;margin:25px auto auto 16px;>' +
                '    <span class="poi-title" style="font-size:18px;font-weight:500;color:#FFFFFF">' +
                '        <%- data.projectName %>' +
                '    </span>' +
                '    <div class="poi-info">' +
                '        <p class="poi-price" style="font-size:14px;font-weight:400;color:#FFFFFF;opacity:0.5;line-height:30px">' +
                '            <%- data.projectType %>' +
                '        </p>' +
                '        <p class="poi-addr" style="font-size:14px;font-weight: 400;color:#FFFFFF;opacity:0.5;"><%- data.kaiYeShiJian %>/<%- data.shangYeMianji %>万平方米</p>' +
                '    </div>' +
                '</div>' +
                '<div class="clear"></div>', {
                data: data,
                label: label
              })
              if (recycledListElement) {
                recycledListElement.innerHTML = innerHTML
                return recycledListElement
              }
              return '<li class="poibox" style="width:388px;height:125px;border-bottom:1px solid rgba(255,255,255,0.1);margin-left:24px">' +
                innerHTML +
                '</li>'
            },
            // 列表节点上监听的事件
            listElementEvents: ['click', 'mouseenter', 'mouseleave'],
            // marker上监听的事件
            markerEvents: ['click', 'mouseover', 'mouseout'],
            // makeSelectedEvents:false,
            selectedClassNames: 'selected',
            autoSetFitView: true
          })
          window.markerList = markerList
          markerList.on('selectedChanged', function (event, info) {
            checkBtnStats()
            if (info.selected) {
              console.log(info)
              if (info.selected.marker) {
                // 更新为选中样式
                info.selected.marker.setIconStyle(selectedIconStyle)
              }
              // 选中并非由列表节点上的事件触发，将关联的列表节点移动到视野内
              if (!info.sourceEventInfo.isListElementEvent) {
                if (info.selected.listElement) {
                  scrollListElementIntoView($(info.selected.listElement))
                }
              }
            }
            if (info.unSelected && info.unSelected.marker) {
              // 更新为默认样式
              info.unSelected.marker.setIconStyle(defaultIconStyle)
            }
          })
          markerList.on('listElementMouseenter markerMouseover', function (event, record) {
            if (record && record.marker) {
              forcusMarker(record.marker)
              // this.openInfoWindowOnRecord(record)
              // 非选中的id
              if (!this.isSelectedDataId(record.id)) {
                // 设置为hover样式
                record.marker.setIconStyle(hoverIconStyle)
                // this.closeInfoWindow()
              }
            }
          })
          markerList.on('listElementMouseleave markerMouseout', function (event, record) {
            if (record && record.marker) {
              if (!this.isSelectedDataId(record.id)) {
                // 恢复默认样式
                record.marker.setIconStyle(defaultIconStyle)
              }
            }
          })
          // 数据输出完成
          markerList.on('renderComplete', function (event, records) {
            checkBtnStats()
          })
          // 加载门店列表数据
          function loadData (src, callback) {
            const params = {
              brand_id: that.brandId,
              cityId: that.cityId,
              gbid: that.gbid,
              pageNum: 0,
              pageSize: 0,
              projectType: that.projectType,
              regionalType: 2,
              state: that.state,
              time: (that.time !== 'null' && that.time) ? that.time : ''
            }
            that.axios.post(api.getBrandAnalysisOfDistributionOfCity, params)
              .then((res) => {
                that.storeData = res.data.data
                markerList.render(that.storeData)
                if (callback) {
                  callback(null, that.storeData)
                }
              })
          }
          var $btns = $('#btnList input[data-path]')
          //  检测各个button的状态
          function checkBtnStats () {
            $('#btnList input[data-enable]').each(function () {
              var $input = $(this)
              var codeEval = $input.attr('data-enable')
              /* eslint-disable */
              $input.prop({
                disabled: !eval(codeEval)
              })
              /* eslint-enable */
            })
          }
          $('#btnList').on('click', 'input', function () {
            var $input = $(this)
            var dataPath = $input.attr('data-path')
            var codeEval = $input.attr('data-eval')
            /* eslint-disable */
            if (dataPath) {
              loadData(dataPath)
            } else if (codeEval) {
              eval(codeEval)
            }
            /* eslint-enable */
            checkBtnStats()
          })
          loadData($btns.attr('data-path'))
          function forcusMarker (marker) {
            marker.setTop(true)
            // 不在地图视野内
            if (!(map.getBounds().contains(marker.getPosition()))) {
              // 移动到中心
              map.setCenter(marker.getPosition())
            }
          }
          function isElementInViewport (el) {
            var rect = el.getBoundingClientRect()
            return (
              rect.top >= 0 &&
              rect.left >= 0 &&
              rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
              rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
            )
          }
          function scrollListElementIntoView ($listEle) {
            if (!isElementInViewport($listEle.get(0))) {
              $('#panel').scrollTop($listEle.offset().top - $listEle.parent().offset().top)
            }
            // 闪动一下
            $listEle
              .one('webkitAnimationEnd oanimationend msAnimationEnd animationend',
                function (e) {
                  $(this).removeClass('flash animated')
                }).addClass('flash animated')
          }
        }
      )
    }
  }
}
</script>

<style scoped lang="stylus">
#location
  position fixed
  top 136px
  left 0px
  min-width 350px
  height 30px
  line-height 30px
  padding-left 11px
  padding-right 11px
  background #333333
  opacity 0.8
  font-size 14px
  font-weight 400
  color #FFFFFF
  z-index 100
  span
    opacity 0.8
.brand-Map
  height 700px
  width 100%
  // position relative
#brandMap
  height 100%
  position relative
#container
  height 100%
  width 100%
  position relative
#panel
  position absolute
  top 0
  bottom 0
  right 0
  height 700px
  // overflow auto
  width 434px
  z-index 999
  background #23252B
  color #fff
  opacity 0.95
#brandName
  height 144px
  box-sizing border-box
  padding 23px 0 0 26px
#brandTittle
  font-size 18px
  font-weight 500
  color #FFFFFF
#brandShop
  font-weight 500
  color #FFFFFF
  font-size 14px
  margin-top 4px
  span
    color rgba(255, 161, 52, 1)
.brandtBtn
  margin-top 20px
  text-align center
  line-height 28px
  width 108px
  height 28px
  background rgba(255, 255, 255, 0.1)
  border 1px dashed rgba(255, 255, 255, 0.4)
  border-radius 14px 14px 14px 0px
  font-size 12px
  font-weight 400
  color #FFFFFF
.store
  width 232px
  height 28px
  background linear-gradient(to right, rgba(255, 161, 52, 0.3), rgba(255, 255, 255, 0))
#storeTitle
  margin-left 13px
  height 28px
  line-height 28px
  font-size 16px
  font-weight 500
  color #FFFFFF
#myList
  padding 0
  margin 0
  list-style none
.BrandList
  overflow-x hidden
  overflow scroll
  height 650px
#btnList
  position absolute
  right 400px
  top 0
  padding 0
  margin 0
  z-index 999
/deep/ .amap-logo
  display none !important
/deep/ .amap-copyright
  opacity 0 !important
/deep/ .amap-ui-smp-ifwn-content-body
  border none
  background rgba(0, 0, 0, 0.5)
  border-radius 3px
  text-align center
/deep/ .amap-ui-infowindow-close, .amap-ui-smp-ifwn-def-tr-close
  display none
/deep/ .amap-ui-infowindow-title, .amap-ui-smp-ifwn-info-title
  font-size 16px
  font-weight 500
  color #FFFFFF
  border none
/deep/ .amap-ui-smp-ifwn-combo-sharp
  display none
/deep/ .toBrandDetail
  color #FFFFFF
  line-height 28px
  font-weight 500
  font-size 14px
</style>
